//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-924:_2812,_1204,_7616,_636,_4068,_7064,_424,_7584;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-924')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-924', "_2812,_1204,_7616,_636,_4068,_7064,_424,_7584");
        }
      }catch (ex) {
        console.error(ex);
      }